$white:    #fff !default;
//$gray-90: #f1f1f1 !default;
//$gray-80: #eaeaea !default;
//$gray-70: #d3d3d3 !default;
//$gray-60: #ced4da !default;
$gray-50: #f3f3f3 !default;
//$gray-40: #979797 !default;
$gray-30: #909090 !default;
$gray-20: #717d85 !default;
//$gray-10: #212529 !default;
$black:    #000 !default;


// -- Colors -- //
$brand-primary: #009ee1 !default;
$brand-primary-hover: #007aae;

$lightest: $white !default;
$darkest: $black!default;
$text-color: $gray-20 !default;

// -- Typography -- //
$font-size-normal: 1rem !default;
$font-size-small: 0.875rem !default;
$font-size-extra-small: 0.75rem !default;

$font-weight-normal: 400 !default;
$font-weight-semi-bold: 600 !default;
$font-weight-bold: 700 !default;

// -- Breakpoints (Semantic-UI -- //
$mobileBreakpoint            : 320px;
$tabletBreakpoint            : 768px;
$computerBreakpoint          : 992px;
$largeMonitorBreakpoint      : 1200px;
$widescreenMonitorBreakpoint : 1920px;