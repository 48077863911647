.fitKiel-app {
  height: 100%;
}

.fitKiel-layout {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
}

.fitKiel-main-columns {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

.fitKiel-main-content {
  flex-grow: 1;
  background-color: #f3f3f3;
  overflow-y: auto;
}

.fitKiel-main-container {
  padding: 42px 20px;
}