html {
  height: auto;
  background-color: $gray-50;
}

.ui.primary.button {
  background-color: $brand-primary;

  @include hover-focus-active {
    background-color: $brand-primary-hover;
  }

  &:disabled {
    background-color: rgba(0, 0, 0, 0.87);
    opacity: 1;
  }
}

.ui.modal > .actions {
  &:after {
    display: block;
    clear: both;
    content: "";
  }
}

.pusher {
  height: 100%;
}

.scrolling.content {
  max-height: calc(80vh - 10em);
  overflow: auto;
  margin: 1em 0;
}
.scrolling.contentNoMargin {
  max-height: calc(80vh - 10em);
  overflow: auto;
}

.column.right.floated {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-end !important;
  @media only screen and (max-width: 767px) {
    justify-content: flex-start !important;
  }
}

.ui.stackable.grid {
  @media only screen and (max-width: 767px) {
    margin-bottom: 0 !important;
  }
}

.ui.stackable.grid > .column:not(.row) {
  @media only screen and (max-width: 767px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.ui.table:not(.unstackable) thead tr {
  @media only screen and (max-width: 767px) {
    border-radius: 0.28571429rem 0.28571429rem 0 0;
    background: #f9fafb;
  }
}
