$spinner__color: #000;

.fitKiel-spinner {
  text-align: center;
}

.fitKiel-spinner__dot1,
.fitKiel-spinner__dot2,
.fitKiel-spinner__dot3 {
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 100%;
  display: inline-block;
  animation: fitKiel-spinner__delay 1.4s infinite ease-in-out both;
}

.fitKiel-spinner__dot1 {
  animation-delay: -0.32s;
}

.fitKiel-spinner__dot2 {
  animation-delay: -0.16s;
}

@keyframes fitKiel-spinner__delay {
  0%, 80%, 100% {
    transform: scale(0);
  } 40% {
      transform: scale(1.0);
    }
}
