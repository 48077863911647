@import "../../scss/variables";

.fitKiel-topMenu-profileItem__name {
  font-size: 14px;
}

.fitKiel-topMenu-profileItem__role {
  padding-top: 8px;
  font-size: 12px;
}

.fitKiel-topMenu {
  margin: 0 !important;
  padding-left: 175px;
  border: none !important;

  @media only screen and (min-device-width: $tabletBreakpoint) {
    flex: 0 0 64px;
  }
}

.fitKiel-topMenu__item:not(.dropdown) {
  font-size: 16px;
  text-transform: uppercase !important;
  color: #a8b8c4 !important;
}

.fitKiel-topMenu__item:not(.dropdown).active {
  background-color: $brand-primary !important;
  color: #fff !important;
}

.fitKiel-topMenu__dropdownItem {
  font-size: 16px;
  height: 64px;
  text-transform: uppercase !important;
  color: #a8b8c4 !important;
}

.fitKiel-topMenu__logo {
    width: 125px;
    float: left;
    left: 20px;
    z-index: 99;
    position: absolute;
    transform: scale(1);
}

.fitKiel-topMenu__sidebar-toggle {
  display: none !important;
}

// Reduce font-size of items first
@media only screen and (max-width: 1450px) {
  .fitKiel-topMenu__item {
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 1280px) {
  .fitKiel-topMenu__sidebar-toggle {
    display: flex !important;
  }
  .fitKiel-topMenu__item {
    display: none !important;
  }
  // Workaround to display items in sidebar
  .sidebar .fitKiel-topMenu__item {
    display: block !important;
  }
  .fitKiel-topMenu__logo {
    width: 90px;
  }
  .fitKiel-topMenu {
    padding-left: 110px;
  }
}
